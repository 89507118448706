import React, { useMemo, useEffect } from 'react';
import dynamic from 'next/dynamic';
import useTracking from 'components/Globals/Analytics';

import ContentWithFilters from 'components/Globals/Layout/ContentWithFilters';
import JsonLD, { JSON_LD_TYPES } from 'components/Globals/JsonLD';
import EntitySuggestions from 'components/Globals/EntitySuggestions';
import CustomerLogos from 'components/Globals/CustomerLogos';
import Drawer from 'components/uiLibrary/Drawer';
import EntityTabComponent from 'components/Globals/Layout/StubPage/EntityTabComponent';

import { SECTIONS, COMPONENTS, CATEGORIES, GOOGLE_OLD_TRACKING_SERVICES } from 'components/Globals/Analytics/constants';

import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useEntitySectionTabs } from 'utils/globals';
import { useDialogs } from 'utils/hooks/useDialogs';
import { getCookie } from 'utils/cookie';
import useEntityEditPermission from 'utils/hooks/useEntityEditPermission';
import { TRACK_EVENTS } from 'utils/tracking';
import useScrollBlocker from 'utils/hooks/useScrollBlocker';
import { useProfileNotificationCounts } from 'utils/queries/notificationCounts';
import { useAccessPendingProfileIds, useUserData, useProfileAccesses } from 'utils/hooks/useAuthenticatedUser';
import { ENTITY_TYPE, ENTITY_MAIN_TABS, PRODUCTION_VALIDITY_IDENTIFIERS, TP } from 'constants/index';
import { IS_CHECKOUT_ACTIVE } from 'constants/cookieConstants';
import { useTranslation } from 'src/i18n';

import Explore from '../Explore';

import classes from './BaseWrapper.module.scss';

const EditDisclaimerModal = dynamic(() => import('components/Globals/Accounts/Common/EditDisclaimerModal'));

const artistSuggestionsTrackingData = {
  section: SECTIONS.POPULAR_TODAY,
  component: COMPONENTS.ARTIST_CARD,
  category: CATEGORIES.RECOMMENDED,
};

const organizationSuggestionsTrackingData = {
  section: SECTIONS.POPULAR_TODAY,
  component: COMPONENTS.ORGANIZATION_CARD,
  category: CATEGORIES.RECOMMENDED,
};

const exploreMoreTrackingData = {
  section: SECTIONS.EXPLORE_MORE,
  component: COMPONENTS.NAVIGATE_CTA,
};

export const BaseWrapperChildren = ({ children, isQuickViewMode = false }) => {
  const { entity, entityType, mainPath, subPath, validFilters } = usePageContext();
  const { data: profileAccess, ownerProfile } = useProfileAccesses();
  const { isPreviewModalOpen, isEditDisclaimerModal, isAlreadyClaimedModal } = useDialogs();
  const isOpenPreviewModal = isQuickViewMode || isPreviewModalOpen;
  useScrollBlocker(isOpenPreviewModal);
  const profileIdsWithAccess = useAccessPendingProfileIds();
  const userData = useUserData();
  const hasProfileAccessPending = profileIdsWithAccess?.includes(entity?.id);
  const { isArtistInRoster, hasTargetProfileAccess, hasSubscription } = useEntityEditPermission({
    entityType,
    entity,
  });
  const track = useTracking();
  const { isMobile } = useDeviceTypeLayouts();
  const {
    setIsSwitchProfileModalOpen,
    setIsRequestAccessModalOpen,
    setIsProfileAccessRequestsModalOpen,
    setIsOpenIdentityVerification,
    isArtistNoAccessModalOpen,
  } = useDialogs();
  const { profileNotifications } = useProfileNotificationCounts();
  const tabs = useEntitySectionTabs({ entityType, entity, mainPath });
  const activeTab = tabs?.find(({ key }) => key === mainPath);
  const showPaidSection = entity?.stats?.isPro;

  const jsonLDType = useMemo(() => {
    switch (entityType) {
      case ENTITY_TYPE.ARTIST:
        return JSON_LD_TYPES.SOCIAL_PROFILE;
      case ENTITY_TYPE.ORGANIZATION:
        return JSON_LD_TYPES.LOCAL_BUSINESS;
      case ENTITY_TYPE.PRODUCTION:
        return JSON_LD_TYPES.WEBPAGE;
      default: {
        return null;
      }
    }
  }, [entityType]);

  const commonFilterParams = useMemo(() => {
    let baseFilters = {};

    if (entityType === ENTITY_TYPE.ARTIST) {
      baseFilters = {
        ...baseFilters,
        include_virtual_contributors: true,
      };
    }

    if (mainPath === ENTITY_MAIN_TABS.RENTALS) {
      return {
        ...baseFilters,
        has_active_rental: true,
        approved_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
      };
    }

    return baseFilters;
  }, [mainPath, entityType]);

  const showEntitySuggestions =
    entityType === ENTITY_TYPE.ARTIST &&
    (entity?.stats?.customizations?.suggestedEntities?.visibility || !showPaidSection);

  useEffect(() => {
    const isCheckoutActive = getCookie(IS_CHECKOUT_ACTIVE);
    const noActiveModals = !isCheckoutActive;
    const profileHasAgencyRoster = entity?.agencies?.some(entityAgency =>
      profileAccess?.data?.some(profile => profile.agency?.id === entityAgency?.id),
    );
    const shouldShowSwitchProfileModal =
      entityType === ENTITY_TYPE.ARTIST &&
      !isOpenPreviewModal &&
      ownerProfile?.profile?.id !== entity?.id &&
      profileHasAgencyRoster &&
      !mainPath;

    const shouldShowRequestAccessModal = isArtistInRoster && !hasProfileAccessPending && !hasTargetProfileAccess;

    if (noActiveModals) {
      if (shouldShowSwitchProfileModal) {
        setIsSwitchProfileModalOpen(true);
        track.click(
          {
            name: TRACK_EVENTS.VIEW_ARTIST_SWITCH_PROFILE_MODAL,
          },
          GOOGLE_OLD_TRACKING_SERVICES,
        );
      }
      if (shouldShowRequestAccessModal) {
        setIsRequestAccessModalOpen(true);
      }
    }
  }, [
    entity?.id,
    setIsRequestAccessModalOpen,
    isArtistInRoster,
    setIsSwitchProfileModalOpen,
    hasTargetProfileAccess,
    hasSubscription,
    isOpenPreviewModal,
    hasProfileAccessPending,
    entityType,
    profileIdsWithAccess,
    track,
    ownerProfile?.profile?.id,
    entity?.agencies,
    profileAccess?.data,
    mainPath,
  ]);

  // NOTE: need to check is required
  useEffect(() => {
    if (
      profileNotifications?.profileAccessRequests &&
      !hasProfileAccessPending &&
      !isEditDisclaimerModal &&
      !isArtistNoAccessModalOpen &&
      !isAlreadyClaimedModal &&
      entityType === ENTITY_TYPE.ARTIST
    ) {
      setIsProfileAccessRequestsModalOpen(true);
      track.click(
        {
          name: TRACK_EVENTS.VIEW_PROFILE_ACCESS_REQUEST_MODAL,
        },
        GOOGLE_OLD_TRACKING_SERVICES,
      );
    }
  }, [
    entityType,
    hasProfileAccessPending,
    isAlreadyClaimedModal,
    isArtistNoAccessModalOpen,
    isEditDisclaimerModal,
    profileNotifications?.profileAccessRequests,
    setIsProfileAccessRequestsModalOpen,
    track,
  ]);

  useEffect(() => {
    if (userData?.isSuspicious && entityType === ENTITY_TYPE.ARTIST) {
      setIsOpenIdentityVerification(true);
    }
  }, [userData?.isSuspicious, setIsOpenIdentityVerification, entityType]);

  return (
    <div className={classes.root}>
      {entity && <JsonLD type={jsonLDType} entityType={entityType} entity={entity} activeTab={activeTab} />}
      <div className={classes.tabContent}>
        <ContentWithFilters
          ns="NS_ENTITY_STUB_PAGE"
          entityType={entityType}
          entity={entity}
          filterTypes={
            mainPath &&
            mainPath !== ENTITY_MAIN_TABS.PERFORMANCES &&
            mainPath !== ENTITY_MAIN_TABS.REPERTOIRE &&
            !subPath &&
            validFilters
          }
          commonFilterParams={commonFilterParams}
          filterStickyTop={105}
        >
          {children}
        </ContentWithFilters>
        <div className={classes.exploreContainer}>
          <Explore inline trackingData={exploreMoreTrackingData} />
        </div>
        {[ENTITY_TYPE.ARTIST, ENTITY_TYPE.ORGANIZATION].includes(entityType) && (
          <div className={classes.entitySuggestionsFooter}>
            <EntitySuggestions
              entityType={entityType}
              entity={entity}
              isArrowsEnabled={isMobile}
              hasInlineSuggestions={showEntitySuggestions}
              styles={{
                sectionBlock: classes.footerSectionBlock,
                horizontalScroll: classes.entitySuggestionsFooter__horizontalScroll,
              }}
              trackingData={
                entityType === ENTITY_TYPE.ARTIST ? artistSuggestionsTrackingData : organizationSuggestionsTrackingData
              }
            />
          </div>
        )}
        <CustomerLogos className={classes.trustedByBlock} showTitle />
        {entityType === ENTITY_TYPE.ARTIST && <EditDisclaimerModal profileId={entity?.id} />}
      </div>
    </div>
  );
};

const BaseWrapper = ({ mainPath }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { isPreviewModalOpen, setIsPreviewModalOpen } = useDialogs();

  return (
    <>
      <BaseWrapperChildren>
        <EntityTabComponent mainPath={mainPath} />
      </BaseWrapperChildren>
      {isPreviewModalOpen && (
        <Drawer
          title={t(`${TP}.PROFILE_MANAGE_PREVIEW`)}
          className={classes.drawer}
          isOpen={isPreviewModalOpen}
          onClose={() => setIsPreviewModalOpen(false)}
          styles={{
            headerText: classes.headerText,
            headerCloseIcon: classes.headerCloseIcon,
            content: classes.drawerContent,
            drawerRoot: classes.previewDrawerRoot,
          }}
        >
          <BaseWrapperChildren>
            <EntityTabComponent mainPath={mainPath} isPreviewMode={isPreviewModalOpen} />
          </BaseWrapperChildren>
        </Drawer>
      )}
    </>
  );
};

export default BaseWrapper;
